import Vue from "vue";
import Vuex from "vuex";
import { getConfig, initConfig } from "../../config";
import LangList from "../locales";
Vue.use(Vuex);

// const VENDOR_LIST = {
//   '6789bet': 'vd000',
//   '978bet': 'vd001',
//   'hg9393': 'vd002',
//   '666bet': 'vd003',
//   '6686': 'vd004',
// }

const API_LANG_KEY_TO_I18N = {
  "en-us": "en_US",
  "id-id": "id_ID",
  "ms-my": "ms_MY",
  "vi-vn": "vi_VN",
  "zh-cn": "zh_CN",
  "zh-hk": "zh_HK",
  "hi-in": "hi_IN",
  "ja-jp": "ja_JP",
  "ko-kr": "ko_KR",
  "th-th": "th_TH",
  "zh-tw": "zh_HK",
  "pt-pt": "pt_PT",
};

const navigatorLanguageToKey = (browserLang) => {
  const langMap = {
    en_US: ["en"],
    id_ID: ["id-id", "id"],
    ms_MY: ["ms-my", "ms"],
    vi_VN: ["vi-vn", "vi"],
    zh_CN: ["zh"],
    zh_HK: ["zh-hk", "zh-mo", "zh-tw"],
    hi_IN: ["hi-in", "hi"],
    ja_JP: ["ja-jp", "ja"],
    ko_KR: ["ko-kr", "ko"],
    th_TH: ["th-th", "th"],
    pt_PT: ["pt-pt", "pt"],
  };
  browserLang = !!browserLang && browserLang.toLowerCase();
  if (!browserLang) return null;
  const exactMatching = (list, value) => list.includes(value);
  const fuzzyMatching = (list, value) =>
    list.some((val) => value.includes(val));
  for (const method of [exactMatching, fuzzyMatching]) {
    for (const [langKey, matchingList] of Object.entries(langMap)) {
      if (method(matchingList, browserLang)) return langKey;
    }
  }
  return "en_US";
};

export default async () => {
  await initConfig();
  return new Vuex.Store({
    state: {
      system: (!!window._env_ && window._env_.SYSTEM) || {},
      defaultLocale:
        API_LANG_KEY_TO_I18N[getConfig()?.SYS_CONFIG?.defaultLocal] ||
        process.env.VUE_APP_defaultLocale,
      availableLocales:
        getConfig()?.SYS_CONFIG?.locales.map((item) => {
          return API_LANG_KEY_TO_I18N[item];
        }) || [],
      client: process.env.VUE_APP_CLIENT,
      applicationUrl: [],
      queryLang: null,
      merchantSetting: {},
    },
    getters: {
      system(state) {
        return state.system;
      },
      currentLang(state, getters) {
        // 參數指定語系優先
        if (
          state.queryLang &&
          getters.availableLocales.includes(state.queryLang)
        )
          return state.queryLang;
        // window.navigator.language
        if (
          getters.availableLocales.includes(
            navigatorLanguageToKey(window.navigator.language)
          )
        )
          return navigatorLanguageToKey(window.navigator.language);
        // 圖框ip國家語系
        const ipLang = getters.system.lang || null;
        if (getters.availableLocales.includes(ipLang)) return ipLang;
        // 預設語系最後
        return getters.defaultLocale;
      },
      translateLang(state, getters) {
        console.log("getters.currentLang", getters.currentLang);
        return function (text) {
          // owner replace業主
          const ownerReg = new RegExp("{owner}", "g");
          const mappingI18nText =
            LangList[getters.currentLang] && LangList[getters.currentLang][text]
              ? LangList[getters.currentLang][text]
              : text;
          const brandName = getters.brandName || "";

          return mappingI18nText.replace(ownerReg, brandName);
        };
      },
      mobileconfig_url() {
        return `${getConfig().MOBILECONFIG_URL}`;
      },
      defaultLocale(state) {
        return state.defaultLocale;
      },
      availableLocales(state) {
        return state.availableLocales;
      },
      filenameLang(state, getters) {
        if (process.env.VUE_APP_localeResource.includes(getters.currentLang)) {
          return getters.currentLang;
        }
        return getters.defaultLocale;
      },
      client(state) {
        return state.client;
      },
      cdnLogoUrl(state) {
        return `${getConfig().FE_CDN_URL}/frontend/${
          getConfig().DEPLOY_ENV
        }/fe-images/${getConfig().VENDERID}/logo`;
      },
      applicationUrl(state) {
        return state.applicationUrl;
      },
      brandName(state) {
        return state.merchantSetting.brandName;
      },
    },
    mutations: {
      setApplicationUrl(state, urls) {
        state.applicationUrl = urls;
      },
      setQueryLang(state, lang) {
        state.queryLang = lang;
      },
      setMerchantSetting(state, data) {
        state.merchantSetting = data;
      },
    },
    actions: {
      async getApplicationUrl({ commit }, { token }) {
        try {
          const res = await Vue.axios.get(
            `${getConfig().PLATFORM_API_URL}/user/download/app/urls`,
            {
              headers: {
                ...(token && { Authorization: `Bearer ${token}` }),
              },
            }
          );
          const urls = res.data.data.ios.urls;
          if (urls) {
            commit("setApplicationUrl", urls);
          }
        } catch (error) {
          console.log(error);
        }
      },
      async getMerchantSetting() {
        try {
          const res = await Vue.axios.get(
            `${getConfig().PLATFORM_API_URL}/user/merchantSetting`
          );
          if (res.data.code !== 0) return;
          this.commit("setMerchantSetting", res.data.data);
        } catch (error) {
          this.commit("setMerchantSetting", {});
          console.log(error);
        }
      },
    },
    modules: {},
  });
};
