import en_US from "./en_US.json";
import zh_CN from "./zh_CN.json";
import ms_MY from "./ms_MY.json";
import id_ID from "./id_ID.json";
import vi_VN from "./vi_VN.json";
import zh_HK from "./zh_HK.json";
import ja_JP from "./ja_JP.json";
import ko_KR from "./ko_KR.json";
import hi_IN from "./hi_IN.json";
import th_TH from "./th_TH.json";
import pt_PT from "./pt_PT.json";

const LangList = {
  en_US,
  zh_CN,
  ms_MY,
  id_ID,
  vi_VN,
  zh_HK,
  ja_JP,
  ko_KR,
  hi_IN,
  th_TH,
  pt_PT,
};

export default LangList;
