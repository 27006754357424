const _env = 'dev' // dev, stg, uat, prod

const vendorPattern = process.env.VUE_APP_CLIENT || 'vd001'

let _config = window._env_ || {}
if (process.env.NODE_ENV === 'development') {
    setConfig(devConfig(_env))
}

function devConfig(env) {
    switch (env) {
        case 'dev':
        case 'stg':
        case 'uat':
        return {
            PLATFORM_API_URL: `https://tiger-api.inno${env}.site/platform`,
            FE_CDN_URL: 'https://fe-source.dev.mppwr.com',
            DEPLOY_ENV: env,
            VENDERID: vendorPattern,
            MOBILECONFIG_URL: "https://fe-source.lanlong.org/frontend/prod/customerService/prod/vd004/customerService.mobileconfig"
        }
        default:
        return {
            PLATFORM_API_URL: `https://tiger-api.innodev.site/platform`,
            FE_CDN_URL: 'https://fe-source.dev.mppwr.com',
            DEPLOY_ENV: env,
            VENDERID: vendorPattern,
            MOBILECONFIG_URL: "https://fe-source.lanlong.org/frontend/prod/customerService/prod/vd004/customerService.mobileconfig"
        }
    }
}

function setConfig(config) {
    _config = config
}

export const getConfig = () => { return _config }

const getTigerSystemConfig = async() => {
    try {
        const res = await fetch(`${getConfig().PLATFORM_API_URL}/user/tiger-system/config?terminal=1`)
        const config = await res.json()
        if (config.data) {
            setConfig({ ...getConfig(), SYS_CONFIG: config.data })
        }
    } catch (error) {
        console.log(error)
    }
}

export const initConfig = async() => {
    await getTigerSystemConfig()
}