<template>
    <div class="appStore">
        <div class="header">
            <div class="header__left">
                <img :src="`${cdnLogoUrl}/cs_logo.png`" class="header__left__icon" alt="">
            </div>
            <div class="header__right">
                <div v-if="found" class="header__right__title">{{ translateLang(`brand_title_appType`) }}</div>
                <div v-else class="header__right__title">{{ translateLang(`brand_title`) }}</div>
                <div class="header__right__subtitle">{{ translateLang(`brand_subtitle`) }}</div>
                <div class="header__right__download">
                    <div
                        :class="[(isClick && !isFinish) ? 'header__right__download__btn--active' : '', 'header__right__download__btn']"
                        @click="handleClick"
                    >
                        <div v-if="isClick && !isFinish" class='header__right__download__btn__progressBar' :style="{ transform: `translateX(calc(${progress}% - 100%))`}"></div>
                        <span v-if="isFinish">{{ translateLang('trust') }}</span>
                        <span v-else style="font-family: 'Helvetica Neue';">{{ isClick ? `${progress.toFixed(2)}%` : translateLang('get') }}</span>
                    </div>
                    <div class="header__right__download__description">{{ translateLang('purchase') }}</div>
                </div>
            </div>
        </div>
        <div class="introduce">
            <img :src="require(`@@/${filenameLang}/introduce.svg`)" class="introduce__img" alt="">
        </div>
        <div class="banner">
            <img :src="`${cdnLogoUrl}/serviceDownload/${filenameLang}/banner.png`" class="banner__img" alt="">
        </div>
        <div class="slogan">
            <p v-if="found && found.appType === 8">{{ translateLang(`brand_slogan_${found.appType}`) }}</p>
            <p v-else>{{ translateLang(`brand_slogan`) }}</p>
        </div>
        <div class="score">
            <div class="score__title">{{ translateLang('score') }}</div>
            <img :src="require(`@@/${filenameLang}/score.svg`)" class="score__img" alt="">
        </div>
        <hr>
        <div class="feature">
            <div class="feature__title">{{ translateLang('feature_title') }}</div>
            <div class="feature__version">{{ translateLang('feature_version') }}</div>
            <div class="feature__description">{{ translateLang('feature_description') }}</div>
        </div>
        <hr>
        <div class="information">
            <div class="information__title">{{ translateLang('information') }}</div>
            <div class="information__item">
                <div class="information__item__left">{{ translateLang('size') }}</div>
                <div class="information__item__right">1M</div>
            </div>
            <hr>
            <div class="information__item">
                <div class="information__item__left">{{ translateLang('type') }}</div>
                <div class="information__item__right">{{ translateLang('entertainment') }}</div>
            </div>
            <hr>
            <div class="information__item">
                <div class="information__item__left">{{ translateLang('compatibility') }}</div>
                <div class="information__item__right">{{ translateLang('compatibility_detail') }}</div>
            </div>
            <hr>
            <div class="information__item">
                <div class="information__item__left">{{ translateLang('language') }}</div>
                <div class="information__item__right">{{ translateLang('chinese') }}</div>
            </div>
            <hr>
            <div class="information__item">
                <div class="information__item__left">{{ translateLang('age_rating') }}</div>
                <div class="information__item__right">{{ translateLang('over_18_years_old') }}</div>
            </div>
            <hr>
            <div class="information__item">
                <div class="information__item__left">{{ translateLang('price') }}</div>
                <div class="information__item__right">{{ translateLang('free') }}</div>
            </div>
            <hr>
            <div class="information__item">
                <div class="information__item__left" style="color:#0079FF;">{{ translateLang('privacy_policy') }}</div>
            </div>
            <hr>
            <div class="information__item">
                <div class="information__item__left">{{ translateLang('disclaimer') }}</div>
                <div class="information__item__right">{{ translateLang('disclaimer_description') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: ['appType', 'packageType', 'lang', 'token'],
    data() {
        return {
            isClick: false,
            isFinish: false,
            progress: 0,
            lessPingUrl: '#'
        }
    },
    name: 'AppStore',
    methods: {
        handleClick() {
            // 從平台來
            if (this.found) {
                if (!this.isClick) {
                    this.isClick = true
                    const interval = setInterval(() => {
                        this.progress += 0.01
                        if (this.progress >= 100) {
                            this.isFinish = true
                            clearInterval(interval)
                        }
                    }, 6)
                    return window.location.href = this.lessPingUrl
                }
                if (this.isClick && this.isFinish) return window.location.href = this.mobileconfig_url
                // loading中直接返回
                return
            }
            // 從App來
            window.location.href = this.mobileconfig_url
        },
        findLessPingUrlPromise(urls) {
            const urlPromises = urls.map(url => new Promise((resolve, reject) => {
                const maxWaitingTime = 30000
                // 超時
                setTimeout(() => reject(), maxWaitingTime)
                fetch(url, { mode: 'no-cors' })
                    .then(res => resolve(url))
                    // 無法連上網站
                    .catch(err => reject())
            }))
            return new Promise((resolve, reject) => {
                Promise.any(urlPromises)
                    .then(url => resolve(url))
                    .catch(err => reject(err))
            })
        }
    },
    computed: {
        ...mapGetters(['translateLang', 'mobileconfig_url', 'filenameLang', 'client', 'applicationUrl', 'cdnLogoUrl']),
        found() {
            return this.applicationUrl.find(item => `${item.appType}` === this.appType && `${item.packageType}` === this.packageType)
        }
    },
    created() {
        if (this.lang) this.$store.commit('setQueryLang', this.lang)
        this.$store.dispatch('getApplicationUrl', { token: this.token })
        this.$store.dispatch('getMerchantSetting')
    },
    watch: {
        lang(newLang, oldLang) {
            if (newLang) {
                this.$store.commit('setQueryLang', this.lang)
            } else {
                this.$store.commit('setQueryLang', null)
            }
        },
        found(newObj, oldObj) {
            if(!newObj || !newObj.downloadLinks || newObj.downloadLinks.length === 0) return
            this.lessPingUrl = newObj.downloadLinks[0]
            this.findLessPingUrlPromise(newObj.downloadLinks)
                .then(url => this.lessPingUrl = url)
                .catch(err => console.log(err))
        }
    }
}
</script>

<style lang="scss" scoped>

* {
    box-sizing: border-box;
}

img {
    display: block;
}

.appStore {
    width: 100%;
}

hr {
    margin: 0 16px;
    border: none;
    border-top: 2px solid rgba(0,0,0,0.2);
}

.header {
    display: flex;
    padding: 12px 18px 22px 18px;
    .header__left__icon {
        width: 112px;
    }
    .header__right {
        display: flex;
        flex-direction: column;
        .header__right__title {
            margin: 6px 14px;
            font-size: 22px;
            font-weight: 600;
            letter-spacing: 0.35px;
            font-family: 'PingFang TC';
        }
        .header__right__subtitle {
            margin: 0 14px;
            color: rgba(0,0,0,0.4);
            font-family: sans-serif;
            font-size: 15px;
            letter-spacing: -0.24px;
            line-height: 20px;
        }
        .header__right__download {
            margin: auto 14px 0 14px;
            display: flex;
            align-items: center;
            font-family: "PingFang TC";
            .header__right__download__btn {
                height: 27px;
                width: 67px;
                background-color: #007AFF;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 18px;
                border-radius: 17px;
                margin-right: 10px;
                position: relative;
                overflow: hidden;
                z-index: 1;
                &.header__right__download__btn--active {
                    background-color: #ddd;
                }
                span {
                    position: relative;
                    z-index: 1;
                }
                .header__right__download__btn__progressBar {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #007AFF;
                }
            }
            .header__right__download__description {
                color: rgba(0,0,0,0.3);
                font-size: 11px;
                letter-spacing: 0.07px;
                line-height: 13px;
            }
        }
    }
}

.introduce {
    .introduce__img {
        width: 100%;
    }
}

.banner {
    padding: 0 20px;
    .banner__img {
        width: 100%;
    }
}

.slogan {
    padding: 16px 20px 6px 20px;
    font-family: "PingFang TC";
    font-size: 15px;
    letter-spacing: -0.24px;
    line-height: 20px;
}

.score {
    .score__title {
        padding: 9px 20px 0 20px;
        font-family: "PingFang TC";
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.29px;
        line-height: 28px;
    }
    .score__img {
        width: 100%;
    }
}

.feature {
    padding: 11px 20px 12px 20px;
    .feature__title {
        margin-bottom: 5px;
        font-family: "PingFang TC";
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.29px;
        line-height: 28px;
    }
    .feature__version {
        margin-bottom: 9px;
        color: #8A8A8F;
        font-family: sans-serif;
        font-size: 15px;
        letter-spacing: -0.24px;
        line-height: 18px;
    }
    .feature__description {
        font-family: "PingFang TC";
        font-size: 15px;
        letter-spacing: -0.24px;
        line-height: 20px;
    }
}

.information {
    padding: 11px 20px 10px 20px;
    font-family: "PingFang TC";
    hr {
        margin: 0;
    }
    .information__title {
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.29px;
        line-height: 28px;
    }
    .information__item {
        margin: 9px 0 10px 0;
        display: flex;
        justify-content: space-between;
        .information__item__left {
            color: #8A8A8F;
            font-size: 15px;
            letter-spacing: -0.24px;
            line-height: 21px;
            flex-shrink: 0;
        }
        .information__item__right {
            font-size: 15px;
            letter-spacing: -0.24px;
            line-height: 20px;
            text-align: right;
        }
    }
}
</style>
